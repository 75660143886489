import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "dynamic-static-first-data"
    }}>{`Dynamic static-first data`}</h1>
    <p>{`Now here's where the modern JAMStack gets fun 👉 dynamic static-first data. How do you ensure visitors see fresh data on statically generated pages?`}</p>
    <p>{`With NextJS, you can avoid this issue with `}<inlineCode parentName="p">{`getServerSideProps`}</inlineCode>{`. That leads to load on your servers. And with how NextJS caches pages, you might still show stale data.`}</p>
    <p>{`And sometimes you want to start with a short static preview then expand to more.`}</p>
    <h2 {...{
      "id": "combining-usestaticquery-with-usequery"
    }}>{`Combining useStaticQuery with useQuery`}</h2>
    <p>{`This is the achilles' heel for Gatsby. Combining useQuery and useStaticQuery is the best approach I've found for dynamically refetching data.`}</p>
    <p>{`But it's clunky.`}</p>
    <p>{`You can use the React Query or the Apollo Client approach. Both are clunky in different ways.`}</p>
    <h3 {...{
      "id": "exercise"
    }}>{`Exercise`}</h3>
    <p>{`Continue with your Gatsby project in exercise-5, or jump to mine in `}<inlineCode parentName="p">{`exercise-6`}</inlineCode>{`.`}</p>
    <p>{`Grab your `}<inlineCode parentName="p">{`<LaunchList>`}</inlineCode>{` component, use the static query to load an initial list of 10 launches. Display it during the loading phase of `}<inlineCode parentName="p">{`useQuery`}</inlineCode>{`.`}</p>
    <p>{`Use the `}<inlineCode parentName="p">{`useQuery`}</inlineCode>{` approach to replace the static list with new data when it's ready.`}</p>
    <p>{`The same principle applies whether you're using GraphQL or REST for in-browser data. You'll need to keep both GraphQL queries, they're different.`}</p>
    <h3 {...{
      "id": "solution"
    }}>{`Solution`}</h3>
    <p>{`Find mine in the `}<inlineCode parentName="p">{`exercise-6-solution`}</inlineCode>{` branch.`}</p>
    <h2 {...{
      "id": "nextjs--react-query-rehydration"
    }}>{`NextJS + React Query rehydration`}</h2>
    <p>{`The fantastic integration between NextJS and React Query makes dynamic static-first data far less clunky than Gatsby.`}</p>
    <p>{`Use the same query in your page and in `}<inlineCode parentName="p">{`getServerSideProps`}</inlineCode>{`, hydrate the cache, and React Query handles the rest.`}</p>
    <h3 {...{
      "id": "exercise-1"
    }}>{`Exercise`}</h3>
    <p>{`Take your NextJS project from exercise-5, or jump into mine at `}<inlineCode parentName="p">{`exercise-6`}</inlineCode>{`.`}</p>
    <p>{`Install `}<inlineCode parentName="p">{`react-query`}</inlineCode>{` and add it to your `}<inlineCode parentName="p">{`_app.js`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// pages/_app.js

import { QueryCache, ReactQueryCacheProvider } from "react-query"
import { Hydrate } from "react-query/hydration"

const queryCache = new QueryCache();

function MyApp({ Component, pageProps }) {
    return (
        <ReactQueryCacheProvider queryCache={queryCache}>
            <Hydrate state={pageProps.dehydratedState}>
`}</code></pre>
    <p>{`You'll use `}<inlineCode parentName="p">{`dehydratedState`}</inlineCode>{` to share query cache with the browser app.`}</p>
    <p>{`Change your `}<inlineCode parentName="p">{`launches.js`}</inlineCode>{` page to prefetch the query.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// pages/launches.js
import { QueryCache } from "react-query"
import { dehydrate } from "react-query/hydration"

async function getLaunches(key, limit) {
  const res = await fetch(
    \`https://api.spacex.land/rest/launches-past?limit=\${limit}\`
  )
  return res.json()
}

export async function getServerSideProps() {
  const queryCache = new QueryCache()
  await queryCache.prefetchQuery(["launches-past", 10], getLaunches)

  return {
    props: {
      dehydratedState: dehydrate(queryCache),
    },
  }
}
`}</code></pre>
    <p>{`You can now re-use your query on the frontend.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// pages/launches.js
const Launches = ({ limit = 10 }) => {
  const { isLoading, error, data } = useQuery(
    ["launches-past", limit],
    getLaunches,
    {
      staleTime: 5 * 60 * 1000,
    }
  )

  if (isLoading) {
    // render loading
  } else if (error) {
    // render error
  } else {
    // render list
  }
}
`}</code></pre>
    <p>{`Look at your network log. Notice there's no additional API request or loading state on the frontend.`}</p>
    <p>{`Even if you render this as a child component on a different page. As long as the query key matches and the data's not stale.`}</p>
    <h3 {...{
      "id": "solution-1"
    }}>{`Solution`}</h3>
    <p>{`Find mine in the `}<inlineCode parentName="p">{`exercise-6-solution`}</inlineCode>{` branch.`}</p>
    <h2 {...{
      "id": "bonus-exercise"
    }}>{`Bonus exercise`}</h2>
    <p>{`Try this approach with your bonus exercise solution for rocket pages.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      